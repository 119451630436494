<template>
  <el-card
    style="margin-bottom: 20px"
  >
    <div style="display: flex; flex-wrap: nowrap">
      <div
        class="scales-settings-visibility"
        @click="templateSettingsVisible=!templateSettingsVisible"
      >
        <i v-if="!templateSettingsVisible" class="fas fa-angle-down"></i>
        <i v-else class="fas fa-angle-up"></i>
      </div>
      <div class="scales-settings-name" style="flex: 1 1 auto; margin-right: 20px;">
        <click-to-edit
          :class="{'not-editable': !$canAndRulesPass(user, 'setting.scales_edit')}"
          :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
          v-model="template.name"
          @input="$emit('update-template', template)"
        ></click-to-edit>
      </div>
      <div style="margin-right: 10px">{{ $companyConfiguration.scales.getScaleShowName('design') }}</div>
      <div>ID: {{ template.id }}</div>
    </div>

    <div
      v-show="templateSettingsVisible"
      style="margin-top: 20px;"
    >
      <div
        style="margin-bottom: 20px"
      >
        <el-input
          v-model="template.description"
          :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
          placeholder="Введите комментарий"
          type="textarea"
          :autosize="{ minRows: 2}"
          @change="$emit('update-template', template)"
        >
        </el-input>
      </div>


      <div>
        <el-row :gutter="40" style="margin-bottom: 5px">
          <el-col :span="6">Оценка выполнения цели, %</el-col>
          <el-col :span="18">Уровень достижения цели (факт)</el-col>
        </el-row>
      </div>
      <div
        v-for="scope in template.scopes"
      >
        <el-row :gutter="40" style="margin-bottom: 5px">
          <el-col :span="6">
            <el-input
              v-model="scope.pay"
              :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
              size="mini"
              type="number"
              @change="$emit('update-template', template)"
            ></el-input>
          </el-col>
          <el-col :span="18">
            <el-input
              v-model="scope.target"
              :disabled="!$canAndRulesPass(user, 'setting.scales_edit')"
              size="mini"
              @change="$emit('update-template', template)"
            ></el-input>
          </el-col>
        </el-row>

      </div>

    </div>
  </el-card>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
import companyConfiguration from "../../../plugins/companyConfiguration";
import {mapGetters} from "vuex";

export default {
  name: "design-scale-setting-template",
  components: {LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {
    template: {type: Object, required: true},
  },
  computed: {
    ...mapGetters([ 'user' ]),
  },
  data() {
    return {
      templateSettingsVisible: false,
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>


<style lang="scss" scoped>
.scales-settings-visibility {
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
.scales-settings-name .not-editable{
  margin-top:5px;
}
</style>